.btn{
	padding:13px 50px;
	border-bottom-right-radius:0px;
	&.btn-primary{
		@extend .asleader-gradient;
		border:none;
		color:$brand-primary;
		text-transform: uppercase;
		font-size:13px;
		font-weight: 700;
		letter-spacing:1px;
	}
	&.btn-default{
		padding:0px;
		background:none;
		border:none;
		border-radius:0px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px;
		&.white{
			color:#fff;
		}
		&:not(.white){
			color:$brand-primary;
		}
		.fa{
			margin-left:5px;
			vertical-align: baseline;
			font-size:11px;
		}
	}
}