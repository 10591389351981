$brand-primary:#362448;
$brand-primary-light:#654e7a;

$brand-second:#b2e4aa;
$brand-third:#87e2c3;

$btn-border-radius-base:35px;

$navbar-margin-bottom: 0px;
$navbar-padding-vertical:24px;

$navbar-default-bg:#fff;
$navbar-default-border:#fff;
$navbar-default-link-color:$brand-primary;
$navbar-default-link-hover-color:$brand-primary;
$navbar-default-link-hover-bg:transparent;
$navbar-default-link-active-color:$brand-primary;
$navbar-default-link-active-bg:transparent;

$font-family-base:'ProximaNova',sans-serif;
$font-size-base:14px;
$headings-font-weight:600;

$input-bg:transparent;
$input-border:#695473;
$input-border-radius:0px;

$panel-border-radius:0px;