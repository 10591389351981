header.site{
	.navbar-brand{
		padding:7px 0px 0px;
	}
	font-size:16px;
	.navbar-nav{
		li.dropdown a{
			text-transform: capitalize;
		}
		.dropdown-menu{border-radius:0px;}
	}
}