.btn-label {position: relative;left: -12px;display: inline-block;padding: 6px 12px;background: rgba(0,0,0,0.15);border-radius: 3px 0 0 3px;}
.btn-labeled {padding-top: 0;padding-bottom: 0;}

:focus{outline:none}
.nomargin{margin:0!important;}
.nopadding{padding:0!important;}
.btn-ghost{
	border: 2px solid $brand-primary;
	color:$brand-primary;
	background:transparent;
}
.btn-ghost:hover{color:$brand-primary }
.btn-ghost.white{border: 2px solid #fff; &:hover{color:#fff;} }

.vertical-center{
	display:flex;
	justify-content:center;
	align-items:center;
}


// Multi Brand Colors
.mbc-facebook {color: $mbc-facebook; }
.mbc-facebook-bg {background-color: $mbc-facebook; color:#fff}
.mbc-facebook-bg:hover {background-color: darken($mbc-facebook,10%); color:#fff}

.mbc-twitter {color: $mbc-twitter;}
.mbc-twitter-bg {background-color: $mbc-twitter; color:#fff}
.mbc-twitter-bg:hover {background-color: darken($mbc-twitter,10%); color:#fff}

.asleader-gradient{
	@include gradient-horizontal($brand-second,$brand-third);
}

a.default{
	color: inherit;
	&:hover, &:focus { color:$brand-primary;}
}

a,button{-webkit-transition: all 0.5s ease; -moz-transition:all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease; }

.text-third{
	color:$brand-third;
}