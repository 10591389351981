/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
	
	.contact {
		.contact-form{
			padding-left: 15px;
			padding-right: 15px;
		}
		.contact-form button{
			width: 50%;
		}
		.quick-contact{
			padding: 15px;
			text-align: center;
		}
	}

	.page {
		.intro-title{
			h1{
				font-size: 15px;
			}
		}
		#laj{
			.trainers {
				h3[data-shadowtext]:before{
					right: -5px;
					top: -115px;
				}
			}
		}
	}

	.row{
		margin-left: 15px;
		margin-right: 15px;
	}

	.page{
		&#laj{
			.trainers h3[data-shadowtext]:before{
				right: -25px;
				top: -100px;
				font-size:75px;
			}
		}
		.services{
			.some-services{
				height: 650px;
			}
		}
	}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
	.contact {
		.contact-form{
			padding-left: 15px;
			padding-right: 15px;
		}
		.contact-form button{
			width: 50%;
		}
		.quick-contact{
			padding: 15px;
			text-align: center;
		}
	}

	.row{
		margin-left: 15px;
		margin-right: 15px;
	}

	.page{
		&#laj{
			.trainers h3[data-shadowtext]:before{
				right: -5px;
				top: -115px;
			}
		}
		.services{
			.some-services{
				height: 650px;
			}
		}
	}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

	.page{
		&#home{
			#home-slider{
				.carousel-inner{
					.item{
						h1{
							font-size: 44px;
							min-width: 680px;
						}
					}
				}
			}
			.services{
				.service-description{
					padding:65px 125px;
					h3{
						font-size:25px;
						letter-spacing: 20px;
					}
					.inner{
						width:365px;
					}
				}
				.some-services{
					p{
						//max-height: 100px;
						min-height: 100px;
					}
				}
			}
		}
		&#egitim-programlari{
			.content{
				padding:40px 60px;
			}
		}
		.contact{
			.contact-form{
				padding:50px 125px 20px 50px;
			}
			.quick-contact{
				padding:50px 25px;
			}
		}
	}

	.contact{
		[class*=col-md]{
			padding:0px;
		}

		.quick-contact{
			padding: 15px;
			text-align: left;
		}
	}

	.vertical-center-on-md{
		display:flex;
		justify-content:center;
		align-items:center;
	}

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

	.pull-left-on-md{float:left;}
	.pull-right-on-md{float:right;}

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}



/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

	header.site{
		.navbar-toggle{
			margin-top:16px;
			margin-bottom:15px;
			border-radius:0px;
			border:none;
			@include gradient-vertical($brand-third,$brand-second);
			.icon-bar{
				background:#fff;
			}
		}
	}

	[data-shadowtext]:before{display:none;}

	.page{
		&#home{
			.services{

				.some-services{
					.item{
						margin:30px 0px;
					}
				}

				.service-description{
					padding:30px;
					h3{
						font-size:20px;
						letter-spacing: 12px;
					}
				}

			}
			.latest-insights{
				.item{
					margin-bottom:35px;
				}
			}
		}
		&#egitim-programlari{
			.content{
				padding:16px !important;
			}
		}
		.contact{
			.contact-form,.quick-contact{
				padding:16px;
			}

		}
		.swiper-slide{
			text-align: center;
			img{
				width:100%;
			}
		}
	}

	.vertical-center-on-md{
		text-align: center;
	}

}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {

	.reference-item{
		height:125px !important;
	}

}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {

}