.page#contact{
	.intro{text-align: left}
}

.contact{
	[class*=col-lg]{
		padding:0px;
	}
	.contact-form{
		background:url('../images/contact.jpg') no-repeat;
		background-size:cover;
		color:#fff;

		h3{margin-bottom: 30px;}

		form{
			textarea{
				resize:none
			}
		}

	}
	.quick-contact{
		background:#f6f8fc;
		h4{
			font-weight: 600;
			margin-top:15px;
		}
		p{
			margin:20px 0px;
			line-height:24px;
		}
		ul{
			margin:30px 0px;
			li .fa{font-size:18px;}
		}
		.input-group{
			width:260px;
			position: relative;
			.form-control{
				background:transparent;
				border-color:#474747;
			}
			.btn{
				background:transparent;
				position: absolute;
				padding:7px 10px;
				right:0px;
				top:0px;
				margin:0px;
				font-size:21px;
				z-index:3;
				&:hover{color:#eee}
			}
		}
	}
	.map-wrapper{
		position: relative;
		a.btn{
			position: absolute;
			right:20px;
			bottom:20px;
			left:20px;
			font-size:13px;
		}
	}
}