@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proxima/proximanova-black.eot'); /* IE9 Compat Modes */
  src: url('../fonts/proxima/proximanova-black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/proxima/proximanova-black.woff') format('woff'), /* Modern Browsers */
       url('../fonts/proxima/proximanova-black.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/proxima/proximanova-black.svg#5216c1cacb478b35a513d81c80dc8ad7') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  800;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proxima/proximanova-bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/proxima/proximanova-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/proxima/proximanova-bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/proxima/proximanova-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/proxima/proximanova-bold.svg#d1e00a8fb1fd9b428ab6b90409479fde') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proxima/proximanova-light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/proxima/proximanova-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/proxima/proximanova-light.woff') format('woff'), /* Modern Browsers */
       url('../fonts/proxima/proximanova-light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/proxima/proximanova-light.svg#da6ddaf3cf69afbba47bd74b5010b98d') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  200;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proxima/proximanova-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/proxima/proximanova-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/proxima/proximanova-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/proxima/proximanova-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/proxima/proximanova-regular.svg#2c1459d58560f08b4b0586fbe80aa4cd') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proxima/proximanova-semibold-webfont.eot'); /* IE9 Compat Modes */
  src: url('../fonts/proxima/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/proxima/proximanova-semibold-webfont.woff') format('woff'), /* Modern Browsers */
       url('../fonts/proxima/proximanova-semibold-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/proxima/proximanova-semibold-webfont.svg#67a6c9f6d7dc8f35ecb08de766ea56f0') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  600;
}

