.page{

	&#laj{
		@import "partials/trainers";
	}

	&#egitim-programlari{
		background:#f6f8fc;


		.row-fluid{
			[class*=col-md]{
				padding:0px;
			}
		}
		.sidebar-menu{
			font-size:19px;
			margin: 0px;
			li{
				a{
					display:block;
					padding:28px 50px 28px 0px;
					text-decoration: none;
					position: relative;
					&:after{
						content:'';
						position: absolute;
						right:33px;
						top:calc(50% - 10px);
						width:18px;
						height:20px;
						background:url('../images/icons/plus.png') no-repeat;
					}
				}
				&:not(:last-child){
					a{
						border-bottom:1px solid #f6f8fc;
					}
				}
				&:hover,&.active{
					a{
						color:#fff;
						background:$brand-primary;
						border-bottom-color:$brand-primary;
					}
				}
				&.active{
					a{
						&:after{
							right:36px;
							top:50%;
							width:10px;
							height:6px;
							background:url('../images/icons/minus.png') no-repeat;
						}
					}
				}
			}
		}
		.content{
			background:#f6f8fc;
			text-align: justify;
			margin-bottom: 100px;
			
			h1{
			text-align: left; margin-bottom: 15px;
			}
			h3{letter-spacing: 0; text-align: left; margin-bottom: 15px;}

			.content-tab:not(:first-child){
				display:none;
			}
		}
	}

	&#contact{
		.intro{
			padding:0px;
			position: relative;
			-webkit-transition: all 0.5s ease; -moz-transition:all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease;
			
			@include gradient-horizontal($brand-second,$brand-third);
			#map{z-index:1;position: relative;}

			&:hover:after{
				display:none;
			}

			&:after{
				content:'';
				position: absolute;
				z-index:2;
				top:0px;right:0px;bottom:0px;left:0px;
				opacity:.3;
				@include gradient-horizontal($brand-third,$brand-second);
			}
			.btn{
				position: absolute;
				z-index:3;
				bottom:30px;
			}
		}
	}
	&#trainers{
		.item{
			margin-bottom: 20px;
			img{width:100%;}
			h4{font-weight: 700;}
			p{text-align: justify;margin-bottom: 10px;font-size: 14px;}
		}
	}

}