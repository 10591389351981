body{
	color:$brand-primary;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#map h4{padding-top: 0; font-size:13px;}

.koclar .page .intro{background-image: url('/assets/bg/resources.jpg'); }
.page{
	
	.intro{
		background-image:url('../images/slider.jpg');
		background-size:cover;
		padding:10%;
		text-align: center;
	}

	.intro-title{
		padding:30px 0px;
		@include gradient-horizontal($brand-third,$brand-second);
		h1{
			font-weight: 700;
			text-transform: uppercase;
			font-size: 20px;
			letter-spacing:4px;
			color:#fff;
			margin:0px;
			padding:0px !important;
		}
	}

	h1,h2,h3,h4{margin:0px;padding:15px 0px 0px;}

	

	h3{
		text-transform: uppercase;
		letter-spacing:4px;
		position: relative;
		z-index:1;
		b{
			font-weight: 800;
		}
		&[data-shadowtext]{
			&:before{
				content:attr(data-shadowtext);
				position: absolute;
				z-index:-1;
				top: -27px;
				left: 35px;
				font-weight:800;
				letter-spacing:1px;
				font-size: 60px;
			}
			&.text-center{
				text-align: center;
				margin:auto;
			}
		}
	}

	.content{
		padding: 60px 0px 20px 0px;
		p{
			margin-bottom:20px;
			line-height:26px;
			font-size:16px;
		}
		h5{
			font-size:18px;
			font-weight:700;
		}
	}

	.fullwidth-content{
		overflow: hidden;
		[class*=col-md]{
			padding:0px;
		}

		.img{
			min-height:288px;
			height:100%;
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
		}
		.content{
			background:#f6f8fc;
			padding:30px;
			height:100%;
			display:table;
			width:100%;
			div{
				display:table-cell;
				vertical-align: middle;
			}
		}
	}

	.contact-form .form-group input, .contact-form .form-group textarea {
		color: #ffffff ;
	} 

	iframe{
		width: 100% !important;
	}
}

.navbar-nav .dropdown{
	text-transform: uppercase;
}

.reference-item{
	width:100%;
	background-size:80%;
	background-position:center;
	background-repeat:no-repeat;
	height:260px;
}