.page{
	&#home{

		#home-slider{
			.carousel-inner{
				.item{
					height:500px;
					background-size:cover;
					background-repeat:no-repeat;
					text-align: center;
					.center{
						display: flex;
						align-items: center;
						justify-content: center;
						height:100%;
						width:100%;
					}
					h1{
						color:#fff;
						font-weight:700;
						margin:0px auto;
						padding:0px;
						letter-spacing:2px;
						text-transform: uppercase;
					}
					h4{
						color:#fff;
						font-weight:200;
						min-height: 61px;
					}
					p{
						color:#fff;
						font-size:16px;
						max-width: 600px;
						margin:15px auto;
					}
					.btn-primary{margin-top:30px;}
				}
			}
		}

		.services{
			[class*=col-md]{
				padding:0px;
			}
			.some-services{
				display: flex;
				align-items: center;
				justify-content: center;
				.item{
					padding:0px 40px;
					color:$brand-primary;
					h4{
						font-weight:700;
						margin:15px 0px;
						line-height: 23px;
					}
					p{
						line-height: 22px;
					}
					a.btn{
						color:$brand-third;
						margin-top:20px;
					}
				}
			}
			.service-description{
				background:url('../images/homeabout.png') no-repeat;
				background-size:cover;
				overflow-x:hidden;
				h3{
					color:#fff;
					margin-bottom: 15px;
					&[data-shadowtext]{
						&:before{
							color:#a2dfa5;
							opacity:.8;
						}
					}
				}
				.inner{
					text-align: justify;
					margin-left:auto;
					p{
						color:$brand-primary;
						margin:0px 0px 25px;
						line-height: 22px;
					}
				}
			}
		}
	
		@import "partials/trainers";

		.references{
			img{
				border-top:1px solid #eee;
				padding:55px 0px;
			}
		}
		.latest-insights{
			background:#F9FAFE;
			padding:55px 0px;
			h3{
				padding:0px 0px 55px 0px;
				&[data-shadowtext]{
					&:before{
						color:#F6F7FB;
						left:auto;
						right:80px;
						top:-45px;
					}
				}
			}
			.item{
				margin-bottom: 20px;
				.thumb{
					display:block;
					height:185px;
					background-size:cover;
				}
				h4{
					font-weight: 600;
					line-height:23px;
					a{text-decoration: none;}
				}
				.date{
					color:#ccc;
					margin:10px 0px;
					display:block;
				}
				p{font-size: 15px;}
				a.btn{}
			}
		}
	}
	.swiper-container {
	    width: 100%;
	    height: 100%;
	}
	.swiper-slide {
	    font-size: 18px;
	    background: #fff;
	    width:250px;
	}
}