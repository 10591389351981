.swiper-button-next{
	background:url('../images/icons/rightarrow.png') no-repeat;
	height:88px;
	top:27%;
}
.contentbox{
	padding:55px 0px;
	h3{
		padding:0px 0px 55px 0px;
		&[data-shadowtext]{
			&:before{
				color:#F9F9F9;
				left:auto;
				right:80px;
				top:-45px;
			}
		}
	}
	.col-md-3{
		margin-bottom:20px;
	}
	h4{
		font-weight:600;
		font-size: 16px;
	}
	p{margin-top: 6px;}
	a.btn{
		margin-top:25px;
		color:#fff;
	}
	.thumb{
		width:100%;
		background-size:contain;
		background-position: center;
		background-repeat:no-repeat;
		height:240px;
		display:block;
	}
}